import { render, staticRenderFns } from "./AdminComponent.vue?vue&type=template&id=bbf712e4"
import script from "./AdminComponent.vue?vue&type=script&lang=js"
export * from "./AdminComponent.vue?vue&type=script&lang=js"
import style0 from "./AdminComponent.vue?vue&type=style&index=0&id=bbf712e4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./AdminComponent.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Fcdn.datatables.net%2F2.0.3%2Fcss%2FdataTables.dataTables.css"
if (typeof block0 === 'function') block0(component)

export default component.exports